import { useEffect, useState } from "react";
import ListGallery from "../../components/ListGallery/ListGallery";
import { getLists } from "../../queries/list.queries";

export default function ListsPage () {

    const [lists, setLists] = useState([]);

    useEffect(() => {
        const init = async () => {
            const { data: { data } } = await getLists();
            console.log(data)
            setLists(data);
        }
        init();
    }, [])
    return <div>
        <ListGallery data={lists}/>
    </div>
}
