import { NavLink } from "react-router-dom";

export default function Nav () {
    return <nav className={"d-flex"}>

        <NavLink to={"/"} className={"b-text b-text--logo"}>
            Kamilist
        </NavLink>
    </nav>
}
