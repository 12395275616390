import qs from "qs";
import { API } from "./api";

export const getLists = () => {
    const query = qs.stringify(
        {
            populate: ['image'],
        }
    );
    return API.get(`lists?${query}`);
}

export const getList = (id) => {
    const query = qs.stringify(
        {
            populate: {
                image: "*",
                category: "*",
                items: {
                    populate: ['image'],
                    sort: ['rating:desc'],
                },

            },
        }
    );
    return API.get(`lists/${id}?${query}`);
}
