import ItemCard from "../ItemCard/ItemCard"
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

/**
 *
 * @param data
 * @param enableBtn show a button to enable click on
 * @returns {JSX.Element}
 * @constructor
 */
export default function ItemGallery ({ data, onClickItem }) {
    console.log('##ABEL## >> default >>  ItemGallery', data);
    const { t } = useTranslation()
    return <div className={"row"}>
        {data.map(({ attributes, id }, index) => <Link to={"/items/" + id} className={"d-flex flex-column" +
        " justify-content-between mb-6" +
        " col-6 col-lg-3"} key={index}>
            <ItemCard data={attributes} position={index + 1}/>
            {onClickItem && <button className={"mt-4 b-btn"}
                                    onClick={(e) => onClickItem(e, { attributes, id }, index)}>{t("Select")}</button>}
        </Link>)}
    </div>
}
