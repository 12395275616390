import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getItem, postItem, putItem } from "../../queries/item.queries";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { JwtContext } from "../../contexts/JwtContext";
import FileInput from "../../components/FileInput/FileInput";
import { uploadImageUtil } from "../../utils/uploadImageUtil";
import { InputSwitch } from 'primereact/inputswitch';

export default function ItemDetailPage () {

    const { user } = useContext(JwtContext)
    const [item, setItem] = useState();
    const { id, action } = useParams();
    const { register, handleSubmit, control } = useForm({ values: item })
    const { t } = useTranslation();
    const navigate = useNavigate()

    const onSubmit = async (data) => {
        const formatData = data.image && data.image.data ? { ...data, image: data.image.data } : data

        if (action !== "new") {
            await uploadImageUtil(formatData, "image")
            await putItem(id, formatData);
            navigate("/lists")
        } else {
            await uploadImageUtil(formatData, "image")
            await postItem({ ...formatData, user: user.id, lists: [id] });
            navigate("/lists/" + id)
        }
    }

    useEffect(() => {
        const init = async () => {
            const { data: { data: { attributes } } } = await getItem(id);
            console.log(attributes)
            setItem(attributes);
        }

        if (action !== "new") {
            init();
        }
    }, [])

    return <div>
        <div>

            <div className={"mb-6 row"}>

                <div className={"col-12 col-lg-4"}>

                    <Controller
                        render={({ field }) => (
                            <FileInput {...field}/>
                        )}
                        control={control}
                        name="image"
                    />
                    {/*<ItemCard data={{ image: item.image }}/>*/}
                </div>

            </div>

            <form onSubmit={handleSubmit(onSubmit)}>

                <label>
                    {t("Rating")} <span className={"icon-star-full"}/>
                    <input className={"b-input"} type="number" step={0.01} min={0}
                           max={10} {...register("rating", { min: 0, max: 10 })} />
                </label>

                <label>
                    {t("Title")}
                    <input className={"b-input"} {...register("name")}/>
                </label>

                <label>
                    {t("Description")}
                    <textarea className={"b-input b-input--textarea"} {...register("description")}/>
                </label>

                <label>
                    {t("Notes")}
                    <textarea className={"b-input b-input--textarea"} {...register("notes")}/>
                </label>

                <label className={"d-flex justify-content-between"}>
                    {t("Is watched")}
                    <Controller
                        render={({ field }) => (
                            <InputSwitch className={"g-primereact-input-switch"} checked={field.value}
                                         onChange={field.onChange}/>
                        )}
                        control={control}
                        name="isWatched"
                    />
                </label>

                <div className="d-flex justify-content-end">
                    <button className={"b-btn"}>{t("Save")}</button>
                </div>
            </form>
        </div>


    </div>
}
