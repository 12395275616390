import { useEffect } from "react";
import { useTranslation } from "react-i18next";

let fistTime = true;
export default function SearchAPIAnime ({ searchTerm, setSearchTerm, onSearch }) {

    const { t } = useTranslation();

    useEffect(() => {
        const delayDebounceFn = setTimeout(async () => {
            if (!fistTime) {
                onSearch(searchTerm);
            } else {
                fistTime = !fistTime;
            }
        }, 1000)

        return () => clearTimeout(delayDebounceFn)
    }, [searchTerm])

    return <div>
        <form onSubmit={(e) => e.preventDefault()}>
            <input placeholder={t("Search")} className={"b-input"} type="text" value={searchTerm}
                   onChange={(e) => setSearchTerm(e.target.value)}/>
        </form>

    </div>
}
