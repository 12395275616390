import { API } from "../queries/api";


export const uploadImageUtil = async (obj, key) => {
    const asset = obj[key];
    if (asset && asset.name && asset.type && asset.size) { //if have data, is a
        const formData = new FormData()
        formData.append("files", asset);

        const { data } = await API.post("/upload", formData)
        obj[key] = data[0].id;
    }

}
