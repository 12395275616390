import React, { useContext } from 'react';
import { useForm } from "react-hook-form";
import { API } from "../../queries/api";
import { useNavigate  } from "react-router-dom";
import { ToastContext } from "../../contexts/ToastContext";
import { useTranslation } from "react-i18next";

export default function RegisterPage () {

    const { toast } = useContext(ToastContext);
    const { register, handleSubmit } = useForm();
    const { t } = useTranslation();

    const navigate = useNavigate()

    const doRegister = async formData => {
        await API.post('register', { ...formData });
        toast.current.show({
            severity: 'info',
            summary: t('Confirm your email'),
            detail: t('Your account was created successfully but you need to confirm it through the email we have' +
                ' sent' +
                ' you'),
            life: 3000
        });
        navigate('/login');
    }

    return (
        <>
            <form onSubmit={handleSubmit(doRegister)}>
                <div className="py-4">
                    <label className="b-text-label" htmlFor="name">{t('Name')}</label>
                    <input className="b-input" id="name" type="text"  {...register("name")}/>
                </div>
                <div className="py-4">
                    <label className="b-text-label" htmlFor="email">{t('Email')}</label>
                    <input className="b-input" id="email" type="text"  {...register("email")}
                         />
                </div>
                <div className="py-4">
                    <label className="b-text-label" htmlFor="password">{t('Password')}</label>
                    <input className="b-input" id="password" type="password"
                           {...register("password")}/>
                </div>
                <input className="b-btn b-btn--sec mt-4" type="submit" value={t('Confirm')}/>
            </form>
        </>
    )
}
