import { Route, Routes } from "react-router-dom";
import HomePage from "./HomePage/HomePage";
import ListsPage from "./ListsPage/ListsPage";
import LoginPage from "./LoginPage/LoginPage";
import RegisterPage from "./RegisterPage/RegisterPage";
import PrivateRoute from "../components/PrivateRoute/PrivateRoute";
import ListsDetailPage from "./ListsDetailPage/ListsDetailPage";
import ItemDetailPage from "./ItemDetailPage/ItemDetailPage";
import ItemSearchPage from "./ItemSearchPage/ItemSearchPage";

export default function Pages () {
    return <div className={"mb-8"}><Routes>
        <Route path="" element={<PrivateRoute><ListsPage/></PrivateRoute>}/>
        <Route path="lists" element={<PrivateRoute><ListsPage/></PrivateRoute>}/>
        <Route path="lists/:id" element={<PrivateRoute><ListsDetailPage/></PrivateRoute>}/>
        <Route path="items/:id" element={<PrivateRoute><ItemDetailPage/></PrivateRoute>}/>
        <Route path="lists/:id/search" element={<PrivateRoute><ItemSearchPage/></PrivateRoute>}/>
        <Route path="lists/:id/:action" element={<PrivateRoute><ItemDetailPage/></PrivateRoute>}/>
        <Route path="register" element={<RegisterPage/>}/>
        <Route path="login" element={<LoginPage/>}/>
    </Routes>
    </div>
}
