import Img from "../Img/Img";
import "./ListCard.scss"
export default function ListCard ({ data }) {
    return <div className={"c-list-card"}>
        <div className={"c-list-card__container-img"}>
            <Img className="c-list-card__img" src={data.image.data.attributes.url}/>
        </div>
        <h2 className={"c-list-card__title"}>{data.name}</h2>
    </div>
}
