import React from "react";
import Img from "../Img/Img";
import styles from "./FileInput.module.scss"
import { useTranslation } from "react-i18next";

const FileInput = ({ value, onChange }) => {
    // const { t } = useTranslation()
    return (
        <div className={styles["c-file-input"]}>
            {/*SAVED PREVIEW*/}
            {value && value.data && <Img src={value.data.attributes.url}/>}
            {value && value.name && value.size && <>
                {/*PREVIEW*/}

                <img src={(URL.createObjectURL(value))} alt=""/>
                {/*REMOVE IMAGE BUTTON*/}

                <button className={styles["c-file-input__icon"]} onClick={() => onChange(null)}/>
            </>}
            {/*INPUT FILE*/}
            {!(value && value.name && value.size) && <label className={"m-0"}>
                <span
                    className={styles["c-file-input__icon"] + " " + styles["c-file-input__icon--add"] + " icon-add--alt"}/>
                <input className={"d-none"} onChange={(e) => {
                    onChange(e.target.files[0]);
                    // setPreview(e)
                }} type="file"/>
                {/*<span className={styles["c-file-input__label-upload"]}>{t("Load image")}</span>*/}

            </label>}
        </div>
    );
}

export default FileInput;
