import { useContext, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import ItemGallery from "../../components/ItemGallery/ItemGallery";
import { useTranslation } from "react-i18next";
import SearchAPIAnime from "../../components/SearchAPIAnime/SearchAPIAnime";
import { getItemsByList, postItem } from "../../queries/item.queries";
import { getList } from "../../queries/list.queries";
import { uploadImageUtil } from "../../utils/uploadImageUtil";
import { API } from "../../queries/api";
import { JwtContext } from "../../contexts/JwtContext";

export default function ListsDetailPage () {

    const [list, setList] = useState();
    const { id } = useParams();
    const { t } = useTranslation();
    const { user } = useContext(JwtContext)
    const [items, setItems] = useState([]);
    const [externalItems, setExternalItems] = useState([]);
    const navigate = useNavigate();
    const [searchTerm, setSearchTerm] = useState("")

    const listGet = async () => {
        const { data: { data: { attributes } } } = await getList(id);
        setList(attributes);
        setItems(attributes.items.data);
    }

    const itemsByListGet = async (name) => {
        const { data: { data } } = await getItemsByList(id, name);
        setItems(data);
    }

    const onSearch = async (name) => {
        await apiGet(name)
        await itemsByListGet(name)
    }

    const apiGet = async (name) => {
        if (list.category.data.attributes.name === "Animes") {
            setExternalItems(await animesGet(name))
        } else if (list.category.data.attributes.name === "Movies") {
            setExternalItems(await moviesGet(name))
        } else if (list.category.data.attributes.name === "Games") {
            setExternalItems(await gamesGet(name))
        }
    }


    const animePost = async (e, { attributes }, index) => {

        e.preventDefault()
        e.stopPropagation();
        // if (list.category.data.attributes.name === "Games") {
        //     const { data: { results: resGames } } = await API.get(`https://api.rawg.io/api/games?key=ebfcdc9d66a840b98cd6ea9d3efd987b`)
        // }
        const formatData = attributes.image && attributes.image.data ? {
            ...attributes,
            image: null
        } : attributes
        // const formatData = attributes.image && attributes.image.data ? {
        //     ...attributes,
        //     image: attributes.image.data
        // } : attributes
        await uploadImageUtil(formatData, "image")

        console.log('##ABEL## >> default >>  animePost', id);
        debugger

        await postItem({ ...formatData, user: user.id, lists: [Number(id)] });
        setSearchTerm("")
        await listGet()
    }

    useEffect(() => {listGet()}, [])


    return <div>
        {list &&
        <div>
            <div className={"mb-6 d-block d-lg-flex align-items-center justify-content-between"}>
                <div className={"d-flex align-items-center"}>
                    <Link className="me-4 mb-2 b-text b-text--xl icon-arrow-left" to={"/lists"}/>
                    <h2 className={"mt-0 b-text b-text--xl"}>{list.name}</h2>
                </div>

                <div className={"d-block d-lg-flex align-items-center"}>

                    <Link to={`/lists/${id}/new`}
                          className={"d-block b-btn b-btn--s b-btn--auto"}>{t("Create new")}</Link>
                </div>

            </div>
            {list.category && list.category.data && <div>
                <SearchAPIAnime searchTerm={searchTerm} setSearchTerm={setSearchTerm} onSearch={onSearch}/>
            </div>}


            <div className={"mt-6"}>
                <h2>{t("Yours")}</h2>
                <ItemGallery data={items}/>
            </div>


            {externalItems.length > 0 && <div className={"mt-6"}>
                <h2>{t("Externals")}</h2>
                <ItemGallery data={externalItems} onClickItem={animePost}/>
            </div>}
        </div>
        }
    </div>
}

const animesGet = async (searchTerm) => {
    const { data: { data: resAnimes } } = await API.get(`https://kitsu.io/api/edge/anime?fields%5Banime%5D=slug%2CcanonicalTitle%2Ctitles%2CposterImage%2Cdescription%2CaverageRating%2CstartDate%2CpopularityRank%2CratingRank%2CyoutubeVideoId&filter%5Btext%5D=${searchTerm}&page%5Blimit%5D=20&page%5Boffset%5D=0`)
    return resAnimes.map(({ attributes }) => ({
        attributes: {
            name: attributes.canonicalTitle,
            description: attributes.description,
            date: attributes.startDate,
            image: { data: { attributes: { url: attributes.posterImage.original } } },
            rating: Math.trunc(Number(attributes.averageRating / 10) * 100) / 100,
        }
    }))
}

const moviesGet = async (searchTerm) => {
    const { data: { results: resMovies } } = await API.get(`https://api.themoviedb.org/3/search/movie?api_key=2348a19e2112e4477ee53d99f05252c7&query=${searchTerm}`)
    return resMovies.map((attributes) => ({
        attributes: {
            name: attributes.original_title,
            description: attributes.overview,
            date: attributes.release_date,
            image: { data: { attributes: { url: "https://image.tmdb.org/t/p/w500" + attributes.poster_path } } },
            rating: Math.trunc(attributes.vote_average * 10) / 10,
        }
    }))
}


const gamesGet = async (searchTerm) => {
    const { data: { results: resGames } } = await API.get(`https://api.rawg.io/api/games?key=ebfcdc9d66a840b98cd6ea9d3efd987b&search=${searchTerm}`)
    return resGames.map((attributes) => ({
        attributes: {
            name: attributes.name,
            description: attributes.description,
            date: attributes.released,
            image: { data: { attributes: { url: attributes.background_image } } },
            rating: Math.trunc(attributes.score * 100) / 100,
        }
    }))
}
