import qs from "qs";
import { API } from "./api";


export const getItem = (id) => {
    const query = qs.stringify(
        {
            populate: ['image'],
        }
    );
    return API.get(`items/${id}?${query}`);
}


export const getItemsByList = (idList, itemName) => {
    const query = qs.stringify(
        {
            populate: ['image'],
            sort: ['rating:desc'],
            filters: {
                lists: idList,
                name: {
                    $containsi: itemName,
                },

            },
        },
    );
    return API.get(`items?${query}`);
}


export const postItem = (data) => {
    const query = qs.stringify(
        {
            populate: ['image'],
        }
    );
    return API.post(`items?${query}`, { data });
}


export const putItem = (id, data) => {
    const query = qs.stringify(
        {
            populate: ['image'],
        }
    );
    return API.put(`items/${id}?${query}`, { data });
}
