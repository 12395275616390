import Img from "../Img/Img";
import "./ItemCard.scss"

export default function ItemCard ({ data, position }) {
    return <div className={"c-item-card"} data-fn={"c-item-card--" + position}>
        <div className={"c-item-card__container-img"}>

            <span className={"c-item-card__position"}>{position}</span>
            {data.image && data.image.data && <Img className="c-item-card__img" src={data.image.data.attributes.url}/>}
        </div>
        {data.rating >= 0 && <h3 className={"b-text"}>{data.rating} <span className={"icon-star-full"}/></h3>}

        <h2 className={"c-item-card__title"}>{data.name}</h2>

    </div>
}
