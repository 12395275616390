import ListItem from "../ListCard/ListCard";
import { Link } from "react-router-dom";

export default function ListGallery ({ data }) {
    return <div className={"row"}>
        {data.map(({ attributes, id }, index) => <Link to={"/lists/" + id} className={"u-text-decoration-none col-6" +
        " col-lg-3"} key={index}>
            <ListItem data={attributes}/>
        </Link>)}
    </div>
}
