import './App.css';
import Pages from "./pages/Pages";
import Nav from "./components/Nav/Nav";
import { JwtContext } from "./contexts/JwtContext";
import { ToastContext } from "./contexts/ToastContext";
import { BrowserRouter } from "react-router-dom";
import React, { useState } from 'react';
import { getCookieUtil } from "./utils/getCookieUtil";

function App () {

    const [jwt, setJwt] = useState(!!getCookieUtil('token') || false);

    const unParsedUser = getCookieUtil('user');
    const [user, setUser] = useState(unParsedUser.length > 0 ? JSON.parse(unParsedUser) : {});

    const [toast, setToast] = useState(null);

    return (
        <BrowserRouter>
            <JwtContext.Provider value={{ jwt, setJwt, user, setUser }}>
                <ToastContext.Provider value={{ toast, setToast }}>
                    <div className={"container"}>
                        <div className={"mb-6"}>
                            <Nav/>

                        </div>
                        <Pages/>
                    </div>
                </ToastContext.Provider>

            </JwtContext.Provider>
        </BrowserRouter>


    );
}

export default App;
